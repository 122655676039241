import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Expert Reproductive Genetics Insight </title>
                    <meta name="description" content="Discover Grow Consulting, led by experts Jenn and Gary, specializing in Women's Health and Reproductive Genetics. Offering advanced services in IVF management, reproductive genetics, third-party reproduction, and patient-centered fertility care, we are dedicated to enhancing outcomes and guiding you through your fertility journey with expertise and compassion. Explore our tailored solutions in reproductive health and genetics, ensuring quality and regulatory compliance." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
