import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import Team from "../elements/Team";
// import BrandTwo from "../elements/BrandTwo";
import Header from '../elements/common/Header';
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";

import about from "../assets/images/about/about-us.jpg";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Empowering Fertility Journeys",
    description:
      "Navigate the complexities of reproductive health with our expert, personalized consulting services.",
    buttonText: "Contact Us",
    buttonLink: "#contact",
  },
];

class HomeParticles extends Component {
  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Grow Consulting" />

        {/* Start Header Area  */}
        <Header />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div
          className="slider-activation slider-creative-agency with-particles"
          id="home"
        >
          <div className="bg_image bg_image--27">
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title theme-gradient">
                            {value.title}
                          </h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2 btn-primary-color"
                              href={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--1" id="about">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={about}
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">About Us</h2>
                      <p>
                        <strong>Grow Consulting: Pioneering Women’s Health and Reproductive Genetics</strong>
                      </p>
                      <p className="description">
                        Founded by Jenn Machovina and Gary Harton, Grow Consulting-Fertility & Genetics is the embodiment of a shared passion and unparalleled expertise in Women’s Health and Reproductive Genetics. As a married couple and leaders in the field, Jenn and Gary combine their distinct skills to steer their customers towards excellence and success.
                        <br /><br />
                        Jenn & Gary are a dynamic duo in the field of Women's Health and Reproductive Genetics. Jenn, an expert in reproductive endocrinology and infertility nursing since 2000, specializes in Third-Party Reproduction, including Donor Gamete IVF and Gestational Surrogacy. Her significant contributions to leading U.S. IVF practices and global consultancy, especially in FDA regulations and ASRM guidelines, have made her a key figure in the field. Gary, with over 35 years of experience in embryology/andrology, cytogenetics, and clinical genetics, brings his extensive knowledge in laboratory and executive roles to further advancements in Reproductive Genetics and Fertility. His expertise in molecular genetics and IVF center management is vital for improving client services and patient outcomes. Together, they combine clinical insight, patient-centered approach, and scientific expertise to enhance care and innovation in reproductive health.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        {/* Start Service Area  */}
        <div
          className="service-area creative-service-wrapper ptb--120 bg_color--5"
          id="services"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="section-title text-left mb--30">
                  <h2>Our Services</h2>
                  <p>
                    Explore our diverse range of specialized services, each tailored to enhance your journey in reproductive health and genetics.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Team Area  */}
        <div className="rn-team-area ptb--120 bg_color--1" id="team">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                  <h2 className="title">Skilled Team</h2>
                  <p>
                    Meet our dedicated team, combining expertise and compassion to guide your reproductive health journey.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Testimonial Area */}
        {/* <div
          className="rn-testimonial-area bg_color--5 ptb--120"
          id="testimonials"
        >
          <div className="container">
            <Testimonial />
          </div>
        </div> */}
        {/* End Testimonial Area */}

        {/* Start Contact Us */}
        <div className="rn-contact-us bg_color--5 ptb--120" id="contact">
          <Contact />
        </div>
        {/* End Contact Us */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Brand Area */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default HomeParticles;
