import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaLinkedinIn } from "react-icons/fa";

import team1 from "../assets/images/team/Gary-Harton-headshot.jpg";
import team2 from "../assets/images/team/Jenn-headshot.jpg";

let TeamContent = [
  {
    images: team1,
    title: "Gary Harton, PhD",
    designation: "Founder",
    bio: "/team/gary",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/garyharton/",
      },
    ],
  },
  {
    images: team2,
    title: "Jenn Machovina, RN BSN",
    designation: "Founder",
    bio: "/team/jenn",
    socialNetwork: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/jennifer-machovina-rn-bsn-675b316/",
      },
    ],
  },
];

class Team extends Component {
  render() {
    const { column } = this.props;
    return (
      <React.Fragment>
        {TeamContent.map((value, i) => (
          <div className={`${column}`} key={i}>
            
              <div className="team">
                <div className="thumbnail">
                  <img src={value.images} alt="Team Member Image" />
                </div>
                <div className="content">
                  <h4 className="title">{value.title}</h4>
                  <p className="designation">{value.designation}</p>
                  <Link className="bio" to={value.bio}>Bio</Link>
                </div>
                <ul className="social-icon">
                  {value.socialNetwork.map((social, index) => (
                    <li key={index}>
                      <a target="_blank" href={`${social.url}`}>{social.icon}</a>
                    </li>
                  ))}
                </ul>
              </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default Team;
