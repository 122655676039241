import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Scrollspy from "react-scrollspy";
import { FiX, FiMenu } from "react-icons/fi";
import logoLight from "../../assets/images/logo/grow-consulting-website-logo.png";
import logoAllDark from "../../assets/images/logo/grow-consulting-website-logo.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
    };
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const offset = window.scrollY;
    if (offset > 100) {
      this.setState({ isSticky: true });
    } else {
      this.setState({ isSticky: false });
    }
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    const { isSticky } = this.state;

    return (
      <header className={`header-area formobile-menu header--fixed default-color ${isSticky ? 'sticky' : ''}`}>
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <Link  to="/">
                <img className="logo-1" src={logoLight} alt="Logo Images" />
                <img className="logo-2" src={logoAllDark} alt="Logo Images" />
              </Link>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <Scrollspy
                className="mainmenu"
                items={[
                  "home",
                  "about",
                  "services",
                  "team",
                  // "testimonials",
                  "contact",
                ]}
                currentClassName="is-current"
                offset={-200}
              >
                <li onClick={this.CLoseMenuTrigger}>
                  <a href="/#home">Home</a>
                </li>
                <li onClick={this.CLoseMenuTrigger}>
                  <a href="/#about">About</a>
                </li>
                <li onClick={this.CLoseMenuTrigger}>
                  <a href="/#services">Services</a>
                </li>
                <li onClick={this.CLoseMenuTrigger}>
                  <a href="/#team">Team</a>
                </li>
                {/* <li onClick={this.CLoseMenuTrigger}>
                  <a href="/#testimonials">Testimonials</a>
                </li> */}
                <li onClick={this.CLoseMenuTrigger}>
                  <a href="/#contact">Contact</a>
                </li>
              </Scrollspy>
            </nav>
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
