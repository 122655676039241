/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const submitContactForm = /* GraphQL */ `
  mutation SubmitContactForm(
    $name: String!
    $email: String!
    $phoneNumber: String!
    $subject: String!
    $message: String!
    $recaptchaToken: String!
  ) {
    submitContactForm(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      subject: $subject
      message: $message
      recaptchaToken: $recaptchaToken
    )
  }
`;
