import React from "react";

import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../elements/common/Header";
import Footer from "../component/footer/FooterTwo";

const Team = () => {
  return (
    <>
      <PageHelmet pageTitle="Gary Harton, PhD" />
      {/* Start Header Area  */}
      <Header />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Gary Harton, PhD"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Team Area  */}
        <div className="rn-team-wrapper ptb--120 bg_color--1">
          <div className="rn-team-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title mb--30">
                    <p>
                      Dr. Harton received his Bachelor of Science degree from James Madison University and his Ph.D. at the University of Kent, Canterbury, UK. His thesis title was Facilitating the widespread use of preimplantation genetic diagnosis and screening through best practice and novel technology development.
                    </p>
                    <p>
                      His career spans over 35 years of clinical laboratory and management positions as well as commercial roles leading Business Development and Market Development teams globally. Gary has been involved in a number of exciting breakthroughs in the field of reproductive genetics including performing the first clinical preimplantation genetic diagnosis (PGD) for an autosomal dominant disease (Marfan syndrome) and performing the first clinical PGD for spinal muscular atrophy (SMA). In addition, Dr. Harton was involved in the team that pioneered non-disclosing Huntington disease PGD where a patient at risk for HD could ensure that their children were free from the mutation without discovering their own HD status. He was also involved in the team that discovered and patented Karyomapping, a revolutionary new technology allowing practically any genetic defect to be diagnosed in embryos using one single array-based test.
                    </p>
                    <p>
                      Dr. Harton is certified by the American Board of Bioanalysis (ABB) as a Technical Supervisor, in Molecular Diagnostics and is a member of the American Society for Reproductive Medicine (ASRM) as well as the European Society of Human Reproduction and Embryology (ESHRE), and Preimplantation Genetic Diagnosis International Society. He is currently the Chair of the PGT Special Interest Group (PGT SIG) at ASRM. Dr. Harton has served as a member of the board of directors for PGD-IS, and a member of the Special Advisory Group for UK-NEQAS which performs external quality assessment schemes for preimplantation genetics. He has also served as Chair of the Steering Committee for the ESHRE PGD Consortium and has been an author on numerous peer reviewed scientific articles, abstracts and book chapters as well. Dr. Harton holds teaching positions at the Eastern VA Medical School (Norfolk, VA), the University of Kent (Canterbury, UK), Assumption University (Worcester, MA) and Bryant University (Smithfield, RI).
                    </p>
                    <p>
                      Beyond the laboratory, Dr. Harton has excelled in multiple senior level and executive roles leading global teams aiming to grow specific markets and increase sales and/or coverage of patients. He’s worked in start-ups (Progyny), smaller biotechnology organizations looking to grow and expand (BioSkryb Genomics) as well as large corporations (Illumina, PerkinElmer) and has led global teams in multiple roles:
                    </p>
                    <ul>
                      <li>
                        <p>
                          BlueGnome/Illumina: As the Associate Director of Market Development at BlueGnome/Illumina, Dr. Harton led a skilled team, including two Global Key Opinion Leaders (KOLs) based in London and Singapore, while also serving as the local KOL for the Americas. He worked in close partnership with a Marketing Leader in San Diego. This team, under Dr. Harton&#39;s guidance, collaborated with Territory Sales, Inside Sales Teams, and Marketing Teams at local, regional, and national levels to enhance the adoption of Illumina&#39;s leading preimplantation genetic testing (PGT) kit, VeriSeq.
                        </p>
                        <p>
                          Dr. Harton and his team focused on engaging clinicians through a variety of educational initiatives aimed at increasing the utilization of VeriSeq. These initiatives included the development and delivery of webinars, hosting informative sessions with guest speakers, and organizing live teaching seminars for local IVF doctors and their teams. In addition, he played an instrumental role in representing the company at conferences and other industry events, supporting discussions, and fostering the growth of the PGT market. Dr. Harton&#39;s comprehensive approach not only underscored his leadership and expertise in market development but also significantly contributed to the expansion and visibility of Illumina&#39;s products in the reproductive health sector.
                        </p>
                      </li>
                      <li>
                        <p>
                          Progyny: At Progyny, Dr. Gary Harton excelled as the Senior Vice President of Commercial and Clinical Excellence. Initially, he was tasked with developing and leading a sales team to commercialize EEVA®, the market&#39;s first FDA-approved embryology prediction tool. His primary goal was to establish a national network of high-quality IVF centers. However, shortly after his start, Progyny shifted its focus towards providing specialized healthcare analysis and payment solutions for large, self-insured companies in the IVF sector.
                        </p>
                        <p>
                          In this new capacity, Dr. Harton successfully led a diverse team comprising nurses, counselors, and advocates, dedicated to ensuring clinical excellence within Progyny&#39;s extensive network of IVF center providers. Concurrently, he managed a team of commercial specialists focused on creating a comprehensive and strategically geo-located IVF network to serve customers globally. Dr. Harton&#39;s leadership and strategic vision were pivotal in both enhancing clinical standards and expanding Progyny&#39;s commercial reach in the reproductive health industry.
                        </p>
                      </li>
                      <li>
                        <p>
                          Igenomix US: As Chief Operating Officer, Dr. Gary Harton spearheaded operations for a company renowned for its Women&#39;s Health-specific genetic laboratories worldwide. In this role, he led the Americas Team, overseeing three fully operational genetics laboratories spread across the United States. His responsibilities extended to all facets of the operations, including sales, support, and clinical support, in one of the largest IVF and Women&#39;s Health markets globally.
                        </p>
                        <p>
                          Under Dr. Harton&#39;s leadership, a team of nearly 60 professionals thrived, managing an excellent and highly profitable laboratory operation. His expertise not only ensured the smooth functioning of these laboratories but also significantly contributed to their commercial success. Dr. Harton&#39;s strategic oversight and management skills were instrumental in maintaining high standards of operational efficiency and profitability, further cementing his reputation as a leading figure in the field of Women&#39;s Health genetics.
                        </p>
                      </li>
                      <li>
                        <p>
                          PerkinElmer Inc.: As the Portfolio Director of PGT at PerkinElmer, Dr. Gary Harton showcased a diverse and extensive skill set, leading the preimplantation genetic testing product line, PG-Seq, within the Reproductive Health Solutions (RHS) business. He adeptly managed Profit and Loss (P&amp;L) responsibilities, covering a broad spectrum of functions including sales, finance, research and development, reagent manufacturing, and marketing strategies.
                        </p>
                      </li>
                      <li>
                        <p>
                          Dr. Harton&#39;s role extended beyond internal management to include leading sales and business development efforts globally. He excelled in building new customer relationships through a mix of remote and in-office presentations, and actively participating in scientific meetings and industry trade shows. His ability to secure notable strategic deals in Europe and Asia further highlights his expertise and success in driving growth and innovation in the field of reproductive health. Dr. Harton&#39;s comprehensive approach and leadership in this role have significantly contributed to the advancement and visibility of PerkinElmer&#39;s reproductive health solutions on an international scale.
                        </p>
                      </li>
                      <li>
                        <p>
                          BioSkryb Genomics: Chief Scientific Officer (CSO)-initially hired to lead the Research &amp; Development, New Product Introduction (NPI), and Computational Biology/Software Development teams. Following the closing of a multimillion dollar deal in the Women’s Health market, he seamlessly transitioned to the role of General Manager (GM) in Reproductive Health, a shift driven by evolving business priorities and significant commercial opportunities in Women’s Health.
                        </p>
                        <p>
                          Notably, as GM of Reproductive Health, Dr. Harton led the BioSkryb teams that performed a full technical transfer of the know-how to manufacture and quality test the ResolveDNA® reagent according to the details of the contract. In addition, Dr. Harton led the internal team that helped Cooper complete and submit the validation paperwork to the New York State Department of Health (NYS DOH). This data was also utilized in clinical marketing at various conferences, demonstrating Dr. Harton’s ability to blend scientific expertise with strategic business acumen, engaging with medical doctors and clinical staff effectively. While leading the Cooper Technical and Clinical Transfer teams, he spearheaded two significant ‘Services Laboratory’ deals, leading to Statements of Work valued at over $250K in their first year.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Team Area  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </>
  );
};

export default Team;
