import React ,{ Component }from "react";
import { FiActivity , FiBriefcase , FiCheckSquare , FiUsers , FiFileText , FiHeart } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Business/Market Development',
        description: 'Targeted analysis of the local/national/international market w/actionable, detailed Attack Plans ready to grow your market.'
    },
    {
        icon: <FiBriefcase />,
        title: 'Laboratory and Services Operations',
        description: 'Analysis of your internal process, current technology platform and overall service with detailed Attack Plan ready to streamline your lab operation.'
    },
    {
        icon: <FiCheckSquare />,
        title: 'Scientific Engagement, Writing, Speaking',
        description: 'Deep experience engaging audiences scientifically through written content, Webinars, and speaking during conferences and local engagements.'
    },
    { 
        icon: <FiUsers />,
        title: 'Regulatory Compliance and FDA Readiness',
        description: 'Analysis of your internal process, protocols, forms and vendors for all third-party reproductive cycles with a detailed Attack Plan ready to streamline Donor Gamete/Gestational Surrogacy program.'
    },
    {
        icon: <FiFileText />,
        title: 'IVF and Clinical Nursing Management and Training',
        description: 'Analysis of your internal nursing protocols, team and process with a detailed Attack Plan ready to streamline and propel your IVF nursing program forward.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--2">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h3 className="title">{val.title}</h3>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
