import React, { useState, useRef } from 'react';
import awsExports from '../../aws-exports';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { submitContactForm } from '../../graphql/mutations';
import ReCAPTCHA from "react-google-recaptcha";

const client = generateClient();

Amplify.configure(awsExports);

const Result = ({ success, message }) => {
    const messageClass = success ? "success-message" : "error-message";
    return <p className={messageClass}>{message}</p>;
};

function ContactForm() {
    const [result, setResult] = useState({ show: false, success: false, message: '' });
    const recaptchaRef = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();

        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        const formData = new FormData(e.target);
        const contactDetails = {
            name: formData.get('name'),
            email: formData.get('email'),
            phoneNumber: formData.get('phoneNumber'),
            subject: formData.get('subject'),
            message: formData.get('message'),
            recaptchaToken: token
        };

        try {
            const { data } = await client.graphql(graphqlOperation(submitContactForm, contactDetails ));

            console.log("Data:", data.submitContactForm);

            if (data.submitContactForm === "true") {
                setResult({ show: true, success: true, message: "Your message has been successfully sent." });
            } else {
                setResult({ show: true, success: false, message: data.submitContactForm.message || "There was an error submitting your message. Please try again." });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setResult({ show: true, success: false, message: 'Submission failed. Please try again.' });
        }

        e.target.reset();

        setTimeout(() => {
            setResult({ show: false, success: false, message: '' });
        }, 5000);
    };

    return (
        <form onSubmit={sendEmail}>
            {/* Form fields */}
            <div className="rn-form-group">
                <input type="text" name="name" placeholder="Your Name" required />
            </div>
            <div className="rn-form-group">
                <input type="email" name="email" placeholder="Your Email" required />
            </div>
            <div className="rn-form-group">
                <input type="text" name="phoneNumber" placeholder="Phone Number" required />
            </div>
            <div className="rn-form-group">
                <input type="text" name="subject" placeholder="Subject" required />
            </div>
            <div className="rn-form-group">
                <textarea name="message" placeholder="Your Message" required></textarea>
            </div>
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />
            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit">Submit</button>
            </div>
            {result.show && <Result success={result.success} message={result.message} />}
        </form>
    );
}

export default ContactForm;
