import React from "react";

import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../elements/common/Header";
import Footer from "../component/footer/FooterTwo";

const Team = () => {
  return (
    <>
      <PageHelmet pageTitle="Jenn Machovina, RN BSN" />
      {/* Start Header Area  */}
      <Header />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Jenn Machovina, RN BSN"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Team Area  */}
        <div className="rn-team-wrapper ptb--120 bg_color--1">
          <div className="rn-team-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title mb--30">
                    <p>Jennifer Machovina, RN, BSN, a Clinical Nurse Consultant and Villanova University nursing program alumna, has been an impassioned specialist in reproductive endocrinology and infertility nursing since 2000. She boasts particular expertise in Third-Party Reproduction, including Donor Gamete IVF and Gestational Surrogacy cycles. Before diving into Reproductive Health, Jenn honed her skills at a prominent hospital in Washington, D.C., working in labor &amp; delivery, postpartum care, and the NICU. Her journey in reproductive health has seen her contribute significantly to some of the top IVF practices in the U.S., and she now applies her extensive clinical knowledge and skills to prominent IVF practices worldwide through her consultancy.</p>
                    <br /><br />
                    <p>Jenn&#39;s contributions have been crucial in driving the development and success of IVF and third-party programs at several large fertility clinics. A notable achievement is her significant involvement in the establishment of the Fairfax EggBank, a leading frozen donor egg bank with global recognition.</p>
                    <br /><br />
                    <p>In her consultancy, Jennifer collaborates with clients looking to start or enhance egg donation programs, offering indispensable guidance on FDA regulations, ASRM guidelines, and other vital operational aspects of Third-Party Reproduction. Her extensive clinical experience, coupled with a patient-first approach, empowers her to provide comprehensive insights to clients. This expertise proves invaluable in identifying challenges and formulating solutions to improve patient care and ensure regulatory compliance. Jennifer’s unwavering dedication and skill in her specialty render her a priceless resource in the reproductive health community.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Team Area  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </>
  );
};

export default Team;
